import { defaultCountry } from '@/constants/config';
// import { Vue } from 'vue'
import { createApp } from 'vue';

export default {
	state: {
		currentCountry: localStorage.getItem('country') != null ? JSON.parse(localStorage.getItem('country')) : defaultCountry,
	},
	getters: {
		currentCountry: state => state.currentCountry,
	},
	mutations: {
		setCountry (state, payload) {
			state.currentCountry = payload
			localStorage.setItem('country', JSON.stringify(payload))
		}
	}
}